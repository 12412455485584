.hoverText {
  transition:
    0.35s font-weight,
    0.35s color;
}

.hoverText:hover {
  font-weight: 900;
  color: rgb(255, 238, 194); /* Deviated color */
}

/* To the right */
.hoverText:hover + .hoverText {
  font-weight: 500;
  color: rgb(253, 223, 159); /* Deviated color */
}

.hoverText:hover + .hoverText + .hoverText {
  font-weight: 300;
}

/* To the left */
.hoverText:has(+ .hoverText:hover) {
  font-weight: 500;
  color: rgb(253, 223, 159); /* Deviated color */
}

.hoverText:has(+ .hoverText + .hoverText:hover) {
  font-weight: 300;
}
