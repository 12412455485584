.container {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d1c8;
  color: black;
}

.body{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 70vw;
}

.line{
  height: 1px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
}

.line svg{
  width: 100%;
  height: 500px;
  position: absolute;
  top: -250px;
}

.line svg path{
  stroke: #F8AD13;
  stroke-width: 3px;
  fill: none;
}

.line .box{
  height: 40px;
  width: 100%;
  position: relative;
  top: -20px;
  z-index: 1;
}

.line .box:hover{
  height: 500px;
  top: -250px;
}