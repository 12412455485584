body {
    width: 100vw;
    height: 100vh;
    background: white;
    overflow: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
  }
  
  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: transparent;
  }
  
  button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    
    position: absolute; /* Keep position as absolute */
    top: 0; /* Position buttons at the top of the nav */
    right: 0; /* Position buttons at the right of the nav */
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }
  
  ul {
    padding: 25px;
    position: relative;
    top: 100px;
    width: 250px;
  }
  
  li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
   
  }
  
  .icon-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 40px 0;
    margin-right: 20px;
  }
  
  .text-placeholder {
    border-radius: 5px;
    width: 200px;
    height: 20px;
    flex: 1;
  }
  
  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  