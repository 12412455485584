.titles{
    width: 100%;
    border-top: 1px solid rgba(183, 171, 152, 0.25);
    .title{
        border-bottom: 1px solid rgba(183, 171, 152, 0.25);
        //cursor: default;
        position: relative;
        z-index: 2;
        .wrapper{
            display: inline-block;
            padding-left: 10%;

            p{
                display: inline-block;
                color: #FFFFFF;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 8vw;
                line-height: 7.5vw;
                margin: 0px;
                position: relative;
                z-index: 2;
                &:nth-of-type(2){
                    display: block;
                    position: absolute;
                    color: #ffffff;
                    top: 0;
                    z-index: 1;
                }
            }
        }
    }
    
}