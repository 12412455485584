.main{
    height: 60vw;
    background-color: #0f0f0f;
    margin-top: 100vh;
    margin-bottom: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
        height: 100%;
    }
}